import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import "./footer.scss"

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          site {
            siteMetadata {
              firma
              address {
                street
                number
                zip
                city
              }
              ceo
              email
              amtsgericht
              hrb
              steuernummer
              bank
              iban
            }
          }
        }
      `}
      render={data => {
        let {
          firma,
          address,
          ceo,
          email,
          amtsgericht,
          hrb,
          steuernummer,
          bank,
          iban,
        } = data.site.siteMetadata
        return (
          <footer className="site-footer">
            <div className="site-footer-inner">
              <div className="left">
                <div className="container">
                  <p>
                    <strong>{firma}</strong>
                    <br />
                    {address.street} {address.number}
                    <br />
                    {address.zip} {address.city}
                    <br />
                    Geschäftsführer: {ceo}
                  </p>
                  <p>E-Mail: {email}</p>
                </div>
              </div>
              <div className="right">
                <div className="container">
                  <p>
                    {amtsgericht}
                    <br />
                    HRB: {hrb}
                    <br />
                    Steuernummer: {steuernummer}
                  </p>
                  <p>
                    {bank}
                    <br />
                    IBAN: {iban}
                  </p>
                </div>
                <div className="container">
                  <p>
                    <nav>
                      <Link to="/impressum/">Impressum</Link>
                      <Link to="/datenschutz/">Datenschutz</Link>
                    </nav>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        )
      }}
    />
  )
}
