import React from "react"

export default function HdmLogo(props) {
  return (
    <svg
      version="1.1"
      id="DMH_Logo"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 79.9"
      // xml:space="preserve"
      {...props}
    >
      <g>
        <g>
          <path
            style={{ fill: "currentColor" }}
            d="M22.9,61.6c0-0.7-0.1-1.4-0.4-2.1c-0.3-0.7-0.6-1.3-1.1-1.8c-0.4-0.5-1-0.9-1.6-1.2c-0.6-0.3-1.3-0.5-2-0.5
			H7.2v11h10.4c0.7,0,1.4-0.2,2-0.5c0.6-0.3,1.2-0.7,1.7-1.2c0.5-0.5,0.9-1.1,1.1-1.8C22.8,63,22.9,62.3,22.9,61.6 M7.2,44.3V55h9.7
			c0.7,0,1.4-0.2,2-0.5c0.6-0.3,1.1-0.7,1.6-1.2c0.4-0.5,0.8-1.1,1-1.7c0.2-0.6,0.4-1.3,0.4-2c0-0.7-0.1-1.4-0.4-2
			c-0.2-0.6-0.6-1.2-1-1.7c-0.4-0.5-0.9-0.9-1.5-1.2c-0.6-0.3-1.2-0.4-1.9-0.4H7.2z M24.1,61.7c0,0.9-0.2,1.7-0.5,2.5
			c-0.3,0.8-0.8,1.5-1.4,2.1c-0.6,0.6-1.3,1.1-2.1,1.4c-0.8,0.3-1.6,0.5-2.5,0.5H6v-25h11.3c0.9,0,1.7,0.2,2.4,0.6
			c0.7,0.4,1.3,0.9,1.8,1.5c0.5,0.6,0.9,1.3,1.1,2c0.3,0.8,0.4,1.5,0.4,2.3c0,1.3-0.3,2.4-1,3.5c-0.6,1.1-1.5,1.8-2.6,2.3
			c0.7,0.2,1.3,0.5,1.9,0.9c0.6,0.4,1.1,0.9,1.5,1.5c0.4,0.6,0.7,1.2,0.9,1.9C24,60.3,24.1,61,24.1,61.7"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M43.9,58.6c-0.1-1.2-0.3-2.2-0.7-3.2c-0.4-1-1-1.8-1.7-2.5c-0.7-0.7-1.5-1.3-2.4-1.6
			c-0.9-0.4-1.9-0.6-2.9-0.6c-1,0-2,0.2-2.9,0.6c-0.9,0.4-1.7,1-2.3,1.7c-0.7,0.7-1.2,1.5-1.6,2.5c-0.4,1-0.7,2-0.7,3.1H43.9z
			 M36.3,68.6c-1.2,0-2.4-0.3-3.5-0.8c-1.1-0.5-2-1.2-2.8-2.1c-0.8-0.9-1.4-1.9-1.9-3.1c-0.5-1.2-0.7-2.4-0.7-3.7
			c0-1.3,0.2-2.5,0.7-3.6c0.4-1.1,1.1-2.1,1.9-3c0.8-0.8,1.7-1.5,2.8-2c1.1-0.5,2.2-0.7,3.5-0.7c1.3,0,2.4,0.2,3.5,0.7
			c1.1,0.5,2,1.2,2.8,2c0.8,0.9,1.4,1.9,1.9,3c0.5,1.2,0.7,2.4,0.7,3.7v0.3c0,0.1,0,0.2,0,0.2H28.6c0.1,1.1,0.4,2.2,0.8,3.2
			c0.4,1,1,1.8,1.7,2.5c0.7,0.7,1.5,1.3,2.4,1.7c0.9,0.4,1.9,0.6,2.9,0.6c0.7,0,1.3-0.1,2-0.3c0.6-0.2,1.3-0.4,1.8-0.8
			c0.6-0.3,1.1-0.7,1.5-1.2c0.4-0.5,0.8-1,1-1.5l1.1,0.3c-0.3,0.6-0.6,1.2-1.1,1.8c-0.5,0.6-1.1,1-1.7,1.4c-0.7,0.4-1.4,0.7-2.2,0.9
			C37.9,68.5,37.1,68.6,36.3,68.6"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M56.5,67.4c0,0-0.2,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.8,0.4s-0.7,0.2-1.1,0.3c-0.4,0.1-0.9,0.1-1.4,0.1
			c-0.9,0-1.7-0.3-2.4-0.8c-0.7-0.5-1-1.3-1-2.2V51H47v-1.1h2.6v-6.3h1.2v6.3h4.3V51h-4.3v14.2c0,0.7,0.3,1.2,0.8,1.6
			c0.5,0.4,1,0.5,1.6,0.5c0.4,0,0.7,0,1.1-0.1c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.2,0.6-0.3c0.2-0.1,0.3-0.2,0.3-0.2L56.5,67.4z
			"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M74.5,58.6c-0.1-1.2-0.3-2.2-0.7-3.2c-0.4-1-1-1.8-1.7-2.5c-0.7-0.7-1.5-1.3-2.4-1.6
			c-0.9-0.4-1.9-0.6-2.9-0.6c-1,0-2,0.2-2.9,0.6c-0.9,0.4-1.7,1-2.3,1.7c-0.7,0.7-1.2,1.5-1.6,2.5c-0.4,1-0.7,2-0.7,3.1H74.5z
			 M66.9,68.6c-1.2,0-2.4-0.3-3.5-0.8c-1.1-0.5-2-1.2-2.8-2.1c-0.8-0.9-1.4-1.9-1.9-3.1C58.2,61.5,58,60.3,58,59
			c0-1.3,0.2-2.5,0.7-3.6c0.4-1.1,1.1-2.1,1.9-3c0.8-0.8,1.7-1.5,2.8-2c1.1-0.5,2.2-0.7,3.5-0.7c1.3,0,2.4,0.2,3.5,0.7
			c1.1,0.5,2,1.2,2.8,2c0.8,0.9,1.4,1.9,1.9,3c0.5,1.2,0.7,2.4,0.7,3.7v0.3c0,0.1,0,0.2,0,0.2H59.2c0.1,1.1,0.4,2.2,0.8,3.2
			c0.4,1,1,1.8,1.7,2.5c0.7,0.7,1.5,1.3,2.4,1.7c0.9,0.4,1.9,0.6,2.9,0.6c0.7,0,1.3-0.1,2-0.3c0.6-0.2,1.3-0.4,1.8-0.8
			s1.1-0.7,1.5-1.2c0.4-0.5,0.8-1,1-1.5l1.1,0.3c-0.3,0.6-0.6,1.2-1.1,1.8c-0.5,0.6-1.1,1-1.7,1.4c-0.7,0.4-1.4,0.7-2.2,0.9
			C68.5,68.5,67.7,68.6,66.9,68.6"
          />
        </g>
        <path
          style={{ fill: "currentColor" }}
          d="M79.7,49.9h1.2v18.3h-1.2V49.9z M79.7,42.5h1.2v3h-1.2V42.5z"
        />
        <g>
          <path
            style={{ fill: "currentColor" }}
            d="M86.9,42.5H88v22.6c0,0.7,0.2,1.2,0.6,1.6c0.4,0.4,1,0.6,1.7,0.6c0.3,0,0.6,0,0.9-0.1c0.4-0.1,0.7-0.1,1-0.3
			l0.3,1c-0.4,0.1-0.8,0.2-1.3,0.3c-0.5,0.1-1,0.1-1.4,0.1c-0.9,0-1.6-0.3-2.2-0.8c-0.6-0.6-0.8-1.3-0.8-2.2V42.5z"
          />
        </g>
        <path
          style={{ fill: "currentColor" }}
          d="M95.4,49.9h1.2v18.3h-1.2V49.9z M95.4,42.5h1.2v3h-1.2V42.5z"
        />
        <g>
          <path
            style={{ fill: "currentColor" }}
            d="M110,67.5c0.8,0,1.6-0.2,2.4-0.5c0.8-0.3,1.5-0.7,2.1-1.2c0.6-0.5,1.2-1.1,1.6-1.7c0.4-0.6,0.7-1.3,0.7-1.9
			v-6.3c-0.3-0.7-0.7-1.4-1.2-2c-0.5-0.6-1.1-1.2-1.7-1.6c-0.6-0.4-1.3-0.8-2-1.1c-0.7-0.3-1.5-0.4-2.2-0.4c-1.2,0-2.2,0.2-3.1,0.7
			c-0.9,0.5-1.7,1.1-2.3,1.9c-0.6,0.8-1.1,1.7-1.4,2.7c-0.3,1-0.5,2-0.5,3c0,1.1,0.2,2.2,0.6,3.2c0.4,1,0.9,1.9,1.6,2.6
			c0.7,0.8,1.5,1.4,2.4,1.8C107.9,67.2,108.9,67.5,110,67.5 M109.8,68.5c-1.3,0-2.4-0.3-3.5-0.8c-1.1-0.5-2-1.3-2.8-2.1
			c-0.8-0.9-1.4-1.9-1.8-3c-0.4-1.1-0.7-2.3-0.7-3.5c0-1.2,0.2-2.4,0.6-3.6c0.4-1.1,1-2.1,1.7-3c0.8-0.9,1.7-1.6,2.7-2.1
			c1-0.5,2.2-0.8,3.5-0.8c0.8,0,1.6,0.1,2.3,0.4c0.7,0.2,1.4,0.6,2,1c0.6,0.4,1.2,0.9,1.7,1.4c0.5,0.5,1,1.1,1.4,1.7v-4.2h1.1v18.9
			c0,1.2-0.2,2.3-0.7,3.2c-0.5,0.9-1.1,1.7-1.8,2.3c-0.8,0.6-1.7,1.1-2.7,1.4c-1,0.3-2.1,0.4-3.2,0.4c-1.1,0-2-0.1-2.9-0.3
			c-0.8-0.2-1.6-0.5-2.2-0.9c-0.6-0.4-1.2-0.8-1.7-1.4c-0.5-0.5-0.9-1.1-1.3-1.7l0.9-0.5c0.8,1.3,1.8,2.3,3,2.9
			c1.3,0.6,2.6,0.9,4.1,0.9c1,0,1.9-0.1,2.8-0.4c0.9-0.3,1.6-0.7,2.3-1.2c0.7-0.5,1.2-1.2,1.6-2c0.4-0.8,0.6-1.7,0.6-2.7v-4.3
			c-0.4,0.6-0.8,1.2-1.4,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-0.6,0.4-1.3,0.6-1.9,0.8C111.2,68.4,110.5,68.5,109.8,68.5"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M129.4,68.6c-1.9,0-3.4-0.7-4.4-2.1c-1-1.4-1.5-3.4-1.5-6.2V49.9h1.2v10.3c0,4.8,1.6,7.3,4.9,7.3
			c0.8,0,1.6-0.1,2.4-0.4c0.8-0.3,1.5-0.7,2.2-1.2c0.7-0.5,1.3-1.1,1.8-1.9c0.5-0.7,0.9-1.5,1.2-2.4V49.9h1.2v16.4
			c0,0.5,0.2,0.8,0.6,0.8v1.1c-0.1,0-0.2,0-0.4,0c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.7-0.4-1.1v-3c-0.8,1.5-1.9,2.7-3.3,3.6
			C132.6,68.1,131,68.6,129.4,68.6"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M158.8,68.2h-1.2V58c0-2.5-0.4-4.4-1.1-5.5c-0.7-1.2-1.8-1.7-3.4-1.7c-0.8,0-1.6,0.2-2.5,0.5
			c-0.8,0.3-1.6,0.7-2.3,1.3c-0.7,0.5-1.3,1.2-1.8,1.9c-0.5,0.7-0.9,1.5-1.1,2.4v11.5h-1.2V49.9h1.2v4.6c0.4-0.7,0.8-1.4,1.4-2
			c0.6-0.6,1.2-1.1,1.9-1.5c0.7-0.4,1.5-0.8,2.2-1c0.8-0.2,1.6-0.4,2.4-0.4c1.9,0,3.3,0.7,4.2,2.1c0.9,1.4,1.3,3.4,1.3,6.2V68.2z"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M172,67.5c0.8,0,1.6-0.2,2.4-0.5c0.8-0.3,1.5-0.7,2.1-1.2c0.6-0.5,1.2-1.1,1.6-1.7c0.4-0.6,0.7-1.3,0.7-1.9
			v-6.3c-0.3-0.7-0.7-1.4-1.2-2c-0.5-0.6-1.1-1.2-1.7-1.6c-0.6-0.4-1.3-0.8-2-1.1c-0.7-0.3-1.5-0.4-2.2-0.4c-1.2,0-2.2,0.2-3.1,0.7
			c-0.9,0.5-1.7,1.1-2.3,1.9c-0.6,0.8-1.1,1.7-1.4,2.7c-0.3,1-0.5,2-0.5,3c0,1.1,0.2,2.2,0.6,3.2c0.4,1,0.9,1.9,1.6,2.6
			c0.7,0.8,1.5,1.4,2.5,1.8C169.9,67.2,170.9,67.5,172,67.5 M171.8,68.5c-1.3,0-2.4-0.3-3.5-0.8c-1.1-0.5-2-1.3-2.8-2.1
			c-0.8-0.9-1.4-1.9-1.8-3c-0.4-1.1-0.7-2.3-0.7-3.5c0-1.2,0.2-2.4,0.6-3.6c0.4-1.1,1-2.1,1.7-3c0.8-0.9,1.7-1.6,2.7-2.1
			c1-0.5,2.2-0.8,3.5-0.8c0.8,0,1.6,0.1,2.3,0.4c0.7,0.2,1.4,0.6,2,1c0.6,0.4,1.2,0.9,1.7,1.4c0.5,0.5,1,1.1,1.4,1.7v-4.2h1.1v18.9
			c0,1.2-0.2,2.3-0.7,3.2c-0.5,0.9-1.1,1.7-1.8,2.3c-0.8,0.6-1.7,1.1-2.7,1.4c-1,0.3-2.1,0.4-3.2,0.4c-1.1,0-2-0.1-2.9-0.3
			c-0.8-0.2-1.6-0.5-2.2-0.9c-0.6-0.4-1.2-0.8-1.7-1.4c-0.5-0.5-0.9-1.1-1.3-1.7l0.9-0.5c0.8,1.3,1.8,2.3,3,2.9
			c1.3,0.6,2.6,0.9,4.1,0.9c1,0,1.9-0.1,2.8-0.4c0.9-0.3,1.6-0.7,2.3-1.2s1.2-1.2,1.6-2c0.4-0.8,0.6-1.7,0.6-2.7v-4.3
			c-0.4,0.6-0.8,1.2-1.4,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-0.6,0.4-1.3,0.6-1.9,0.8C173.1,68.4,172.5,68.5,171.8,68.5"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M191.3,68.6c-1.4,0-2.7-0.2-3.9-0.7c-1.2-0.5-2.3-1.2-3.2-2.2l0.7-0.8c1,0.9,2,1.6,3,2c1,0.4,2.2,0.7,3.5,0.7
			c1.7,0,3.1-0.4,4.2-1.1c1.1-0.7,1.6-1.8,1.6-3.1c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.7-0.8-1.2-1.1c-0.5-0.3-1.2-0.6-1.9-0.8
			c-0.8-0.2-1.6-0.5-2.6-0.7c-1-0.2-1.9-0.5-2.6-0.7c-0.7-0.2-1.4-0.5-1.9-0.8c-0.5-0.3-0.9-0.7-1.1-1.2c-0.2-0.5-0.4-1.1-0.4-1.8
			c0-0.9,0.2-1.7,0.5-2.4c0.4-0.7,0.8-1.2,1.4-1.6c0.6-0.4,1.3-0.8,2.1-1c0.8-0.2,1.6-0.3,2.5-0.3c1.5,0,2.8,0.2,3.8,0.7
			c1.1,0.5,1.9,1.1,2.3,1.9l-0.8,0.6c-0.5-0.7-1.3-1.2-2.3-1.6c-1-0.4-2.1-0.6-3.2-0.6c-0.7,0-1.4,0.1-2,0.2
			c-0.6,0.2-1.2,0.4-1.7,0.7c-0.5,0.3-0.9,0.8-1.1,1.3c-0.3,0.5-0.4,1.1-0.4,1.8c0,0.6,0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9
			c0.4,0.2,0.9,0.5,1.6,0.6c0.6,0.2,1.4,0.4,2.3,0.6c1.1,0.3,2.1,0.5,3,0.8c0.9,0.3,1.6,0.6,2.2,0.9c0.6,0.4,1.1,0.8,1.4,1.3
			c0.3,0.5,0.5,1.2,0.5,2c0,0.8-0.2,1.6-0.5,2.2c-0.3,0.6-0.8,1.2-1.4,1.7c-0.6,0.5-1.3,0.8-2.2,1.1
			C193.2,68.5,192.3,68.6,191.3,68.6"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M210.1,67.5c0.8,0,1.6-0.2,2.4-0.5c0.8-0.3,1.5-0.7,2.1-1.2c0.6-0.5,1.2-1.1,1.6-1.7c0.4-0.6,0.7-1.3,0.7-1.9
			v-6.3c-0.3-0.7-0.7-1.4-1.2-2c-0.5-0.6-1.1-1.2-1.7-1.6c-0.6-0.4-1.3-0.8-2-1.1c-0.7-0.3-1.5-0.4-2.2-0.4c-1.2,0-2.2,0.2-3.1,0.7
			c-0.9,0.5-1.7,1.1-2.3,1.9c-0.6,0.8-1.1,1.7-1.4,2.7c-0.3,1-0.5,2-0.5,3c0,1.1,0.2,2.2,0.6,3.2c0.4,1,0.9,1.9,1.6,2.6
			c0.7,0.8,1.5,1.4,2.4,1.8C208,67.2,209,67.5,210.1,67.5 M209.9,68.5c-1.3,0-2.4-0.3-3.5-0.8c-1.1-0.5-2-1.3-2.8-2.1
			c-0.8-0.9-1.4-1.9-1.8-3c-0.4-1.1-0.7-2.3-0.7-3.5c0-1.2,0.2-2.4,0.6-3.6c0.4-1.1,1-2.1,1.7-3c0.8-0.9,1.7-1.6,2.7-2.1
			c1-0.5,2.2-0.8,3.5-0.8c0.8,0,1.6,0.1,2.3,0.4c0.7,0.2,1.4,0.6,2,1s1.2,0.9,1.7,1.4c0.5,0.5,1,1.1,1.4,1.7v-4.2h1.1v18.9
			c0,1.2-0.2,2.3-0.7,3.2c-0.5,0.9-1.1,1.7-1.8,2.3c-0.8,0.6-1.7,1.1-2.7,1.4c-1,0.3-2.1,0.4-3.2,0.4c-1.1,0-2-0.1-2.9-0.3
			c-0.8-0.2-1.6-0.5-2.2-0.9c-0.6-0.4-1.2-0.8-1.7-1.4c-0.5-0.5-0.9-1.1-1.3-1.7l0.9-0.5c0.8,1.3,1.8,2.3,3,2.9
			c1.3,0.6,2.6,0.9,4.1,0.9c1,0,1.9-0.1,2.8-0.4c0.9-0.3,1.6-0.7,2.3-1.2c0.7-0.5,1.2-1.2,1.6-2c0.4-0.8,0.6-1.7,0.6-2.7v-4.3
			c-0.4,0.6-0.8,1.2-1.4,1.7c-0.5,0.5-1.1,0.9-1.7,1.3c-0.6,0.4-1.3,0.6-1.9,0.8C211.3,68.4,210.6,68.5,209.9,68.5"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M239.1,58.6c-0.1-1.2-0.3-2.2-0.7-3.2c-0.4-1-1-1.8-1.7-2.5c-0.7-0.7-1.5-1.3-2.4-1.6
			c-0.9-0.4-1.9-0.6-2.9-0.6c-1,0-2,0.2-2.9,0.6c-0.9,0.4-1.7,1-2.3,1.7c-0.7,0.7-1.2,1.5-1.6,2.5c-0.4,1-0.7,2-0.7,3.1H239.1z
			 M231.5,68.6c-1.2,0-2.4-0.3-3.5-0.8c-1.1-0.5-2-1.2-2.8-2.1c-0.8-0.9-1.4-1.9-1.9-3.1c-0.5-1.2-0.7-2.4-0.7-3.7
			c0-1.3,0.2-2.5,0.7-3.6c0.4-1.1,1.1-2.1,1.9-3c0.8-0.8,1.7-1.5,2.8-2c1.1-0.5,2.2-0.7,3.5-0.7c1.3,0,2.4,0.2,3.5,0.7
			c1.1,0.5,2,1.2,2.8,2c0.8,0.9,1.4,1.9,1.9,3c0.5,1.2,0.7,2.4,0.7,3.7v0.3c0,0.1,0,0.2,0,0.2h-16.4c0.1,1.1,0.4,2.2,0.8,3.2
			c0.4,1,1,1.8,1.7,2.5c0.7,0.7,1.5,1.3,2.4,1.7c0.9,0.4,1.9,0.6,2.9,0.6c0.7,0,1.3-0.1,2-0.3c0.6-0.2,1.3-0.4,1.8-0.8
			c0.6-0.3,1.1-0.7,1.5-1.2c0.4-0.5,0.8-1,1-1.5l1.1,0.3c-0.3,0.6-0.6,1.2-1.1,1.8c-0.5,0.6-1.1,1-1.7,1.4c-0.7,0.4-1.4,0.7-2.2,0.9
			C233.1,68.5,232.3,68.6,231.5,68.6"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M249.8,68.6c-1.4,0-2.7-0.2-3.9-0.7c-1.2-0.5-2.3-1.2-3.2-2.2l0.7-0.8c1,0.9,2,1.6,3,2c1,0.4,2.2,0.7,3.5,0.7
			c1.7,0,3.1-0.4,4.2-1.1c1.1-0.7,1.6-1.8,1.6-3.1c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.7-0.8-1.2-1.1c-0.5-0.3-1.2-0.6-1.9-0.8
			c-0.8-0.2-1.6-0.5-2.6-0.7c-1-0.2-1.9-0.5-2.6-0.7c-0.7-0.2-1.4-0.5-1.9-0.8c-0.5-0.3-0.9-0.7-1.1-1.2c-0.2-0.5-0.4-1.1-0.4-1.8
			c0-0.9,0.2-1.7,0.5-2.4c0.4-0.7,0.8-1.2,1.4-1.6c0.6-0.4,1.3-0.8,2.1-1c0.8-0.2,1.6-0.3,2.5-0.3c1.5,0,2.8,0.2,3.8,0.7
			c1.1,0.5,1.9,1.1,2.3,1.9l-0.8,0.6c-0.5-0.7-1.3-1.2-2.3-1.6c-1-0.4-2.1-0.6-3.2-0.6c-0.7,0-1.4,0.1-2,0.2
			c-0.6,0.2-1.2,0.4-1.7,0.7c-0.5,0.3-0.9,0.8-1.1,1.3c-0.3,0.5-0.4,1.1-0.4,1.8c0,0.6,0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9
			c0.4,0.2,0.9,0.5,1.6,0.6c0.6,0.2,1.4,0.4,2.3,0.6c1.1,0.3,2.1,0.5,3,0.8c0.9,0.3,1.6,0.6,2.2,0.9c0.6,0.4,1.1,0.8,1.4,1.3
			c0.3,0.5,0.5,1.2,0.5,2c0,0.8-0.2,1.6-0.5,2.2c-0.3,0.6-0.8,1.2-1.4,1.7c-0.6,0.5-1.3,0.8-2.2,1.1
			C251.8,68.5,250.9,68.6,249.8,68.6"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M276.3,58.6c-0.1-1.2-0.3-2.2-0.7-3.2c-0.4-1-1-1.8-1.7-2.5c-0.7-0.7-1.5-1.3-2.4-1.6
			c-0.9-0.4-1.9-0.6-2.9-0.6c-1,0-2,0.2-2.9,0.6c-0.9,0.4-1.7,1-2.3,1.7c-0.7,0.7-1.2,1.5-1.6,2.5c-0.4,1-0.7,2-0.7,3.1H276.3z
			 M268.7,68.6c-1.2,0-2.4-0.3-3.5-0.8c-1.1-0.5-2-1.2-2.8-2.1c-0.8-0.9-1.4-1.9-1.9-3.1c-0.5-1.2-0.7-2.4-0.7-3.7
			c0-1.3,0.2-2.5,0.7-3.6c0.4-1.1,1.1-2.1,1.9-3c0.8-0.8,1.7-1.5,2.8-2c1.1-0.5,2.2-0.7,3.5-0.7c1.3,0,2.4,0.2,3.5,0.7
			c1.1,0.5,2,1.2,2.8,2s1.4,1.9,1.9,3c0.5,1.2,0.7,2.4,0.7,3.7v0.3c0,0.1,0,0.2,0,0.2H261c0.1,1.1,0.4,2.2,0.8,3.2
			c0.4,1,1,1.8,1.7,2.5c0.7,0.7,1.5,1.3,2.4,1.7c0.9,0.4,1.9,0.6,2.9,0.6c0.7,0,1.3-0.1,2-0.3c0.6-0.2,1.3-0.4,1.8-0.8
			s1.1-0.7,1.5-1.2c0.4-0.5,0.8-1,1-1.5l1.1,0.3c-0.3,0.6-0.6,1.2-1.1,1.8c-0.5,0.6-1.1,1-1.7,1.4c-0.7,0.4-1.4,0.7-2.2,0.9
			C270.3,68.5,269.5,68.6,268.7,68.6"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M281.7,42.5h1.2v22.6c0,0.7,0.2,1.2,0.6,1.6c0.4,0.4,1,0.6,1.7,0.6c0.3,0,0.6,0,0.9-0.1
			c0.4-0.1,0.7-0.1,1-0.3l0.3,1c-0.4,0.1-0.8,0.2-1.3,0.3c-0.5,0.1-1,0.1-1.4,0.1c-0.9,0-1.6-0.3-2.2-0.8c-0.6-0.6-0.8-1.3-0.8-2.2
			V42.5z"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M290.4,42.5h1.2v22.6c0,0.7,0.2,1.2,0.6,1.6c0.4,0.4,1,0.6,1.7,0.6c0.3,0,0.6,0,0.9-0.1
			c0.4-0.1,0.7-0.1,1-0.3l0.3,1c-0.4,0.1-0.8,0.2-1.3,0.3c-0.5,0.1-1,0.1-1.4,0.1c-0.9,0-1.6-0.3-2.2-0.8c-0.6-0.6-0.8-1.3-0.8-2.2
			V42.5z"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M304.5,68.6c-1.4,0-2.7-0.2-3.9-0.7c-1.2-0.5-2.3-1.2-3.2-2.2l0.7-0.8c1,0.9,2,1.6,3,2c1,0.4,2.2,0.7,3.5,0.7
			c1.7,0,3.1-0.4,4.2-1.1c1.1-0.7,1.6-1.8,1.6-3.1c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.7-0.8-1.2-1.1c-0.5-0.3-1.2-0.6-1.9-0.8
			c-0.8-0.2-1.6-0.5-2.6-0.7c-1-0.2-1.9-0.5-2.6-0.7c-0.7-0.2-1.4-0.5-1.9-0.8c-0.5-0.3-0.9-0.7-1.1-1.2c-0.2-0.5-0.4-1.1-0.4-1.8
			c0-0.9,0.2-1.7,0.5-2.4c0.4-0.7,0.8-1.2,1.4-1.6c0.6-0.4,1.3-0.8,2.1-1c0.8-0.2,1.6-0.3,2.5-0.3c1.5,0,2.8,0.2,3.8,0.7
			c1.1,0.5,1.9,1.1,2.3,1.9l-0.8,0.6c-0.5-0.7-1.3-1.2-2.3-1.6c-1-0.4-2.1-0.6-3.2-0.6c-0.7,0-1.4,0.1-2,0.2
			c-0.6,0.2-1.2,0.4-1.7,0.7c-0.5,0.3-0.9,0.8-1.1,1.3c-0.3,0.5-0.4,1.1-0.4,1.8c0,0.6,0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9
			c0.4,0.2,0.9,0.5,1.6,0.6c0.6,0.2,1.4,0.4,2.3,0.6c1.1,0.3,2.1,0.5,3,0.8c0.9,0.3,1.6,0.6,2.2,0.9c0.6,0.4,1.1,0.8,1.4,1.3
			c0.3,0.5,0.5,1.2,0.5,2c0,0.8-0.2,1.6-0.5,2.2c-0.3,0.6-0.8,1.2-1.4,1.7c-0.6,0.5-1.3,0.8-2.2,1.1
			C306.5,68.5,305.6,68.6,304.5,68.6"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M314.4,59c0-1.3,0.2-2.5,0.7-3.7c0.4-1.1,1.1-2.1,1.9-3c0.8-0.8,1.7-1.5,2.8-2c1.1-0.5,2.3-0.7,3.5-0.7
			c1.6,0,3,0.4,4.3,1.1c1.2,0.7,2.2,1.8,2.8,3l-1.2,0.4c-0.6-1.1-1.4-1.9-2.5-2.5c-1.1-0.6-2.2-0.9-3.5-0.9c-1.1,0-2.1,0.2-3,0.6
			c-0.9,0.4-1.7,1-2.4,1.7c-0.7,0.7-1.2,1.6-1.6,2.6c-0.4,1-0.6,2.1-0.6,3.2c0,1.2,0.2,2.2,0.6,3.3c0.4,1,1,1.9,1.7,2.7
			c0.7,0.8,1.5,1.4,2.4,1.8c0.9,0.4,1.9,0.7,2.9,0.7c0.7,0,1.4-0.1,2-0.3c0.7-0.2,1.3-0.5,1.9-0.8c0.6-0.3,1.1-0.7,1.5-1.1
			c0.4-0.4,0.7-0.9,0.8-1.4l1.2,0.4c-0.2,0.6-0.6,1.2-1,1.8c-0.5,0.5-1,1-1.7,1.4c-0.6,0.4-1.4,0.7-2.1,0.9
			c-0.8,0.2-1.6,0.3-2.4,0.3c-1.2,0-2.4-0.3-3.5-0.8c-1.1-0.5-2-1.2-2.8-2.1c-0.8-0.9-1.5-1.9-1.9-3C314.7,61.5,314.4,60.3,314.4,59
			"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M349.4,68.2h-1.2V58c0-4.8-1.6-7.3-4.8-7.3c-0.8,0-1.6,0.2-2.4,0.5c-0.8,0.3-1.5,0.7-2.2,1.3
			c-0.7,0.5-1.2,1.2-1.7,1.9c-0.5,0.7-0.9,1.5-1.1,2.4v11.5h-1.2V42.5h1.2v12c0.4-0.7,0.8-1.4,1.4-2c0.6-0.6,1.2-1.1,1.8-1.5
			c0.7-0.4,1.4-0.8,2.1-1c0.7-0.2,1.5-0.4,2.3-0.4c2,0,3.4,0.7,4.4,2.2c1,1.4,1.4,3.5,1.4,6.1V68.2z"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M359.5,67.6c0.9,0,1.7-0.1,2.6-0.4c0.9-0.2,1.7-0.6,2.4-1c0.7-0.4,1.3-0.9,1.7-1.4c0.4-0.5,0.7-1.1,0.7-1.7
			v-3.5c-1.8-0.7-3.8-1.1-5.9-1.1c-1,0-1.8,0.1-2.6,0.3c-0.8,0.2-1.5,0.5-2.1,0.9c-0.6,0.4-1,0.8-1.3,1.4c-0.3,0.5-0.5,1.1-0.5,1.8
			s0.1,1.3,0.4,1.8c0.3,0.6,0.6,1.1,1.1,1.5c0.4,0.4,1,0.8,1.6,1C358.1,67.4,358.8,67.6,359.5,67.6 M359.3,68.6
			c-0.8,0-1.6-0.1-2.4-0.4c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.1-1.3-1.8c-0.3-0.7-0.5-1.4-0.5-2.2c0-0.8,0.2-1.5,0.6-2.1
			c0.4-0.6,0.9-1.2,1.6-1.7c0.7-0.5,1.5-0.8,2.4-1.1c0.9-0.3,1.9-0.4,3-0.4c1,0,2.1,0.1,3.1,0.2c1,0.2,2,0.4,2.8,0.7v-2
			c0-1.8-0.5-3.3-1.6-4.4c-1-1.1-2.5-1.6-4.2-1.6c-0.9,0-1.8,0.2-2.9,0.6c-1,0.4-2,0.9-3,1.7l-0.6-0.9c2.3-1.6,4.5-2.4,6.5-2.4
			c2.1,0,3.8,0.6,5.1,1.9c1.2,1.3,1.8,3,1.8,5.2v9.6c0,0.5,0.2,0.8,0.6,0.8v1.1c-0.1,0-0.1,0-0.2,0h-0.2c-0.4,0-0.7-0.1-1-0.4
			c-0.3-0.3-0.4-0.7-0.4-1.1v-1.6c-0.8,1.1-1.9,2-3.3,2.6C362.3,68.3,360.9,68.6,359.3,68.6"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M374.2,68.2V51h-2.6v-1.1h2.6v-0.7c0-2.2,0.4-4,1.3-5.2c0.9-1.2,2.1-1.9,3.7-1.9c0.7,0,1.3,0.1,2,0.3
			c0.6,0.2,1.1,0.5,1.5,0.9l-0.6,0.8c-0.3-0.3-0.7-0.5-1.2-0.6c-0.5-0.2-1-0.2-1.6-0.2c-1.3,0-2.2,0.5-2.9,1.5c-0.7,1-1,2.5-1,4.5
			v0.7h5.4V51h-5.4v17.3H374.2z"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M391.9,67.4c0,0-0.2,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.8,0.4c-0.3,0.1-0.7,0.2-1.1,0.3
			c-0.4,0.1-0.9,0.1-1.4,0.1c-0.9,0-1.7-0.3-2.4-0.8c-0.7-0.5-1-1.3-1-2.2V51h-2.6v-1.1h2.6v-6.3h1.2v6.3h4.3V51h-4.3v14.2
			c0,0.7,0.3,1.2,0.8,1.6c0.5,0.4,1,0.5,1.6,0.5c0.4,0,0.7,0,1.1-0.1c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.2,0.6-0.3
			c0.2-0.1,0.3-0.2,0.3-0.2L391.9,67.4z"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M427,64.1c-2.5,2.8-5.5,4.3-8.7,4.3c-1.7,0-3.3-0.4-4.7-1.1c-1.4-0.7-2.7-1.7-3.7-2.9c-1-1.2-1.8-2.6-2.4-4.1
			c-0.6-1.5-0.9-3-0.9-4.6c0-1.6,0.3-3.2,0.8-4.8c0.6-1.5,1.4-2.9,2.4-4.1c1-1.2,2.2-2.1,3.6-2.8c1.4-0.7,2.9-1.1,4.6-1.1
			c1.3,0,2.4,0.1,3.4,0.4c1,0.3,1.9,0.6,2.7,1.1c0.8,0.5,1.4,1.1,2,1.7c0.6,0.7,1,1.4,1.4,2.2l-1,0.5c-0.9-1.7-2.1-2.9-3.6-3.7
			c-1.5-0.8-3.1-1.1-5-1.1c-1.6,0-3,0.3-4.2,1c-1.3,0.7-2.3,1.5-3.2,2.6c-0.9,1.1-1.6,2.3-2,3.7c-0.5,1.4-0.7,2.8-0.7,4.2
			c0,1.5,0.3,3,0.8,4.4c0.6,1.4,1.3,2.6,2.3,3.7c1,1.1,2.1,1.9,3.4,2.5c1.3,0.6,2.7,1,4.2,1c1.6,0,3.1-0.4,4.5-1.1
			c1.4-0.7,2.8-1.9,4.2-3.5v-5.4h-5.8v-1.1h6.9v12H427V64.1z"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M459.7,68.2h-1.2V58c0-2.5-0.4-4.3-1.1-5.5c-0.8-1.2-1.9-1.8-3.5-1.8c-0.8,0-1.6,0.2-2.3,0.5
			c-0.7,0.3-1.4,0.7-2,1.3c-0.6,0.5-1.1,1.2-1.6,1.9c-0.4,0.8-0.8,1.6-1,2.4v11.4h-1.2V58c0-2.5-0.4-4.4-1.1-5.5
			c-0.7-1.2-1.9-1.7-3.5-1.7c-1.6,0-3,0.6-4.3,1.7c-1.2,1.1-2.1,2.6-2.6,4.4v11.5h-1.2V49.9h1.2v4.6c0.8-1.6,1.8-2.7,3-3.6
			c1.2-0.8,2.6-1.3,4.1-1.3c1.6,0,2.8,0.5,3.8,1.4c1,0.9,1.6,2.2,1.7,3.7c1.8-3.4,4.2-5.1,7.3-5.1c2,0,3.4,0.7,4.3,2.1
			c0.9,1.4,1.3,3.4,1.3,6.2V68.2z"
          />
          <path
            style={{ fill: "currentColor" }}
            d="M473.3,67.5c1.1,0,2.2-0.2,3.1-0.7c1-0.5,1.8-1.1,2.4-1.9c0.7-0.8,1.2-1.7,1.6-2.7c0.4-1,0.6-2,0.6-3
			c0-1.1-0.2-2.1-0.5-3.1c-0.4-1-0.9-1.9-1.5-2.7c-0.6-0.8-1.4-1.4-2.3-1.9c-0.9-0.5-1.9-0.7-3-0.7c-0.8,0-1.6,0.1-2.3,0.4
			c-0.7,0.3-1.4,0.7-2,1.1c-0.6,0.5-1.1,1-1.6,1.6c-0.5,0.6-0.9,1.3-1.2,1.9v6.4c0.1,0.7,0.3,1.4,0.8,2c0.5,0.6,1,1.2,1.7,1.7
			c0.7,0.5,1.4,0.9,2.2,1.1C471.8,67.3,472.6,67.5,473.3,67.5 M473.4,68.6c-1.5,0-2.8-0.4-4.1-1.2c-1.3-0.8-2.3-1.7-3-2.9v3.7h-1.1
			V42.5h1.2v11.6c0.9-1.3,1.9-2.4,3.2-3.2c1.2-0.8,2.6-1.2,4.2-1.2c1.3,0,2.5,0.3,3.6,0.8c1,0.6,1.9,1.3,2.6,2.2
			c0.7,0.9,1.3,1.9,1.7,3c0.4,1.1,0.6,2.3,0.6,3.4c0,1.2-0.2,2.4-0.7,3.6c-0.4,1.1-1.1,2.1-1.9,3c-0.8,0.9-1.7,1.6-2.8,2.1
			C475.8,68.3,474.7,68.6,473.4,68.6"
          />
        </g>
        <polygon
          style={{ fill: "currentColor" }}
          points="504.8,43.2 504.8,54.9 488.2,54.9 488.2,43.2 486.9,43.2 486.9,68.2 488.2,68.2 488.2,56 504.8,56 
		504.8,68.2 506,68.2 506,43.2 	"
        />
        <g>
          <path
            style={{ fill: "currentColor" }}
            d="M27.3,18.4c0-1.8-0.2-3.4-0.7-4.8c-0.5-1.5-1.2-2.7-2.2-3.8c-0.9-1.1-2.1-1.9-3.5-2.5
			c-1.4-0.6-2.9-0.9-4.7-0.9h-7v24.1h7c1.8,0,3.4-0.3,4.8-0.9c1.4-0.6,2.5-1.5,3.5-2.6c0.9-1.1,1.6-2.4,2.1-3.8
			C27,21.7,27.3,20.1,27.3,18.4 M6.3,33V3.8h9.9c2.3,0,4.4,0.4,6.1,1.2c1.7,0.8,3.2,1.8,4.4,3.1c1.2,1.3,2,2.9,2.6,4.6
			c0.6,1.8,0.9,3.7,0.9,5.7c0,2.2-0.3,4.2-1,6c-0.7,1.8-1.6,3.3-2.8,4.6c-1.2,1.3-2.7,2.3-4.4,3c-1.7,0.7-3.6,1-5.8,1H6.3z"
          />
        </g>
        <polygon
          style={{ fill: "currentColor" }}
          points="61.5,3.8 50,23.8 38.6,3.8 35.7,3.8 35.7,33 38.6,33 38.6,9 49.2,27.6 50.9,27.6 61.5,9 61.5,33 
		64.4,33 64.4,3.8 	"
        />
        <polygon
          style={{ fill: "currentColor" }}
          points="91.9,3.8 91.9,16.8 74.6,16.8 74.6,3.8 71.7,3.8 71.7,33 74.6,33 74.6,19.3 91.9,19.3 91.9,33 
		94.8,33 94.8,3.8 	"
        />
      </g>
    </svg>
  )
}
