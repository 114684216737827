import React from "react"

import MdhLogo from "./hdm-logo"

import "./header.scss"

export default function Header() {
  return (
    <header className="site-header">
      <MdhLogo id="logo" />
    </header>
  )
}
