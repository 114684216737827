import React from "react"

import Header from "./layout/header"
import Footer from "./layout/footer"

import "./layout.scss"

export default function Layout({ children }) {
  return (
    <div className="site">
      <Header />
      <main className="site-main">{children}</main>
      <Footer />
    </div>
  )
}
